import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"

const QuestionsStyles = styled.div`
  padding: 3em;

  .frequently-asked-question {
    border: 1px solid black;
    padding: 1em;
    user-select: none;
    margin: 0 auto 1em;
    max-width: 1200px;

    .question {
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 40px;
      cursor: pointer;

      .toggle {
        font-size: 3em;
        font-weight: 700;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .answer {
      display: none;
    }

    &.active {
      .answer {
        display: block;
        border-top: 1px solid lightgray;
        padding-top: 1em;
      }
    }
  }
`

export default function FaqPage({ data }) {
  const siteTitle = "Frequently Asked Questions"
  const faqPage = data.file.childMdx.frontmatter

  const toggleActive = (e) => {
    let wrapper = e.target.parentElement
    let toggle = e.target.querySelector(".toggle")

    if (e.target.nodeName === "P") {
      wrapper = e.target.parentElement.parentElement
      toggle = e.target
    } else if (e.target.nodeName === "H4") {
      wrapper = e.target.parentElement.parentElement
      toggle = e.target.parentElement.querySelector(".toggle")
    }

    wrapper.classList.toggle("active")

    if (wrapper.classList.value.indexOf("active") > -1) {
      toggle.innerHTML = "-"
    } else {
      toggle.innerHTML = "+"
    }
  }

  return (
    <Layout>
      <SEO
        title={siteTitle}
        keywords={[
          `carbon wheels`,
          `carbon fiber wheels`,
          `carbon bicycle wheels`,
          `high performance bicycle wheels`,
          `bike wheels`,
          `bmx cruiser wheels`,
          `29er bmx`,
          `fixed gear wheels`,
          `fixed wheels`,
          `fixie wheels`,
          `track wheels`,
          `road bike wheels`,
          `carbon wheelset`,
          `usa made wheels`,
        ]}
      />
      <Hero image={faqPage.hero.image} title={faqPage.hero.title}>
        <h1>{faqPage.hero.title}</h1>
      </Hero>
      <QuestionsStyles>
        {faqPage.questions.map((question, index) => (
          <div key={index} className="frequently-asked-question">
            <div className="question" onClick={(e) => toggleActive(e)}>
              <h4>{question.question}</h4>
              <p className="toggle">+</p>
            </div>
            <p className="answer">{question.answer}</p>
          </div>
        ))}
      </QuestionsStyles>
    </Layout>
  )
}

export const query = graphql`
  query FaqPageQuery {
    file(name: { eq: "faq" }) {
      name
      childMdx {
        id
        frontmatter {
          templateKey
          hero {
            title
            image {
              name
              childImageSharp {
                gatsbyImageData(quality: 90, height: 500, layout: FULL_WIDTH)
              }
            }
          }
          questions {
            question
            answer
          }
        }
      }
    }
  }
`
